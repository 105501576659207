import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { BlogItem } from 'hooks/useBlogs';
import BlogItemComponent from './BlogItem';

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  width: 100%;
  padding: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `}
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  width: 100%;
`;

const EmptyText = styled(Typography)`
  color: #808abd;
  font-family: Syne;
  font-size: 18px;
  text-align: center;
`;

interface BlogListProps {
  items: BlogItem[];
  activeTag?: string;
}

export default function BlogList({ items, activeTag = 'All' }: BlogListProps) {
  if (items.length === 0) {
    return (
      <EmptyContainer>
        <EmptyText>
          {activeTag === 'All'
            ? 'No blogs available at the moment'
            : `No blogs found in ${activeTag} category`}
        </EmptyText>
      </EmptyContainer>
    );
  }

  return (
    <ListContainer>
      {items.map((item, index) => (
        <BlogItemComponent key={index} data={item} />
      ))}
    </ListContainer>
  );
}
