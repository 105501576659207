import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';

const BLOG_LIST_URL =
  'https://raw.githubusercontent.com/0xLazAI/lazai-web-blogs/main/list.json';
const BLOG_CONTENT_BASE_URL =
  'https://raw.githubusercontent.com/0xLazAI/lazai-web-blogs/main/blogs';
const BLOG_CACHE_KEY = 'lazai_blog_list';

export interface BlogItem {
  title: string;
  desc: string;
  tag: string;
  banner: string;
  time: string;
  link: string;
}

interface BlogData {
  tag: string[];
  list: BlogItem[];
}
const cachedData: BlogItem[] = JSON.parse(
  localStorage.getItem(BLOG_CACHE_KEY) || '[]',
);

export function useBlogs() {
  const [loading, setLoading] = useState(true);
  const [blogItems, setBlogItems] = useState<BlogItem[]>(cachedData);
  const [filteredItems, setFilteredItems] = useState<BlogItem[]>([]);
  const [tags, setTags] = useState<string[]>(['All']);

  const fetchBlogList = async () => {
    try {
      const timestamp = Date.now();
      const response = await axios.get<BlogData>(
        `${BLOG_LIST_URL}?t=${timestamp}`,
      );
      const data = response.data;

      const sortedList = [...data.list].sort((a, b) => {
        const timeA = moment(a.time).valueOf();
        const timeB = moment(b.time).valueOf();
        return timeB - timeA;
      });

      setTags(['All', ...data.tag]);
      setBlogItems(sortedList);
      setFilteredItems(sortedList);

      localStorage.setItem(
        BLOG_CACHE_KEY,
        JSON.stringify({ ...data, list: sortedList }),
      );
      return sortedList;
    } catch (error) {
      console.error('Failed to fetch blog list:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const loadBlogData = useCallback(async () => {
    const cachedData = localStorage.getItem(BLOG_CACHE_KEY);

    if (cachedData) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    return fetchBlogList();
  }, []);

  const filterByTag = useCallback(
    (tag: string, sortOrder: 'desc' | 'asc' = 'desc') => {
      const filtered =
        tag === 'All'
          ? [...blogItems]
          : blogItems.filter((item) => item.tag === tag);

      filtered.sort((a, b) => {
        const timeA = new Date(a.time).getTime();
        const timeB = new Date(b.time).getTime();
        return sortOrder === 'desc' ? timeB - timeA : timeA - timeB;
      });

      setFilteredItems(filtered);
    },
    [blogItems],
  );

  const findBlogByLink = async (link: string) => {
    const cachedData = localStorage.getItem(BLOG_CACHE_KEY);
    if (cachedData) {
      const data: BlogData = JSON.parse(cachedData);
      const blogFromCache = data.list.find((item) => item.link === link);
      if (blogFromCache) {
        return blogFromCache;
      }
    }

    const list = await fetchBlogList();
    return list?.find((item) => item.link === link) || null;
  };

  const fetchBlogContent = useCallback(async (id: string) => {
    try {
      const timestamp = Date.now();
      const response = await axios.get(
        `${BLOG_CONTENT_BASE_URL}/${id}?t=${timestamp}`,
      );
      return response.data;
    } catch (error) {
      console.error('Failed to fetch blog content:', error);
      throw error;
    }
  }, []);

  const sortItems = useCallback((items: BlogItem[], order: 'desc' | 'asc') => {
    const sorted = [...items].sort((a, b) => {
      const timeA = moment(a.time).valueOf();
      const timeB = moment(b.time).valueOf();
      return order === 'desc' ? timeB - timeA : timeA - timeB;
    });
    setFilteredItems(sorted);
  }, []);

  useEffect(() => {
    loadBlogData();
  }, []);

  return {
    loading,
    blogItems,
    filteredItems,
    tags,
    filterByTag,
    findBlogByLink,
    fetchBlogContent,
    refreshData: loadBlogData,
    sortItems,
  };
}
