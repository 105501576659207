import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 24px;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   justify-content: flex-start;;
  `};
`;

const TabItem = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  background: ${({ active }) => (active ? '#3B3F8C' : 'transparent')};

  transition: all 0.3s ease;

  &:hover {
    background: #3b3f8c;
  }
`;

const TabText = styled(Typography)<{ active: boolean }>`
  color: ${({ active }) => (active ? '#FFFFFF' : '#808ABD')};
  font-family: Syne;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  ${TabItem}:hover & {
    color: #ffffff;
  }
`;

interface BlogTabsProps {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const BlogTabs: React.FC<BlogTabsProps> = ({
  tabs,
  activeTab,
  onTabChange,
}) => {
  return (
    <TabsContainer>
      {tabs.map((tab) => (
        <TabItem
          key={tab}
          active={activeTab === tab}
          onClick={() => onTabChange(tab)}
        >
          <TabText active={activeTab === tab}>{tab}</TabText>
        </TabItem>
      ))}
    </TabsContainer>
  );
};

export default BlogTabs;
