import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HoverButton from 'components/HoverButton';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import AnimatedSVG from 'components/AnimatedSVG';
import { Typography, IconButton } from '@mui/material';
import { XSvg } from 'assets/img';
import { AlithGitLink, AlithGitbookLink, AlithXLink } from 'constant';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 100 / 25;
  position: relative;
  z-index: 1;
  gap: 40px;
  background: linear-gradient(90deg, #44aeff 0%, #0051ff 100%);
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  gap: 48px;
  background: linear-gradient(90deg, #44aeff 0%, #0051ff 100%);
  padding: 100px 0;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 999;
`;

const Title = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin-left: 14px;
  height: 121.81px;
`;

const Line = styled(motion.div)`
  height: 4px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  position: relative;
  z-index: 999;
`;

const HoverButtonBox = styled(motion.div)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const HoverGitBtn = styled.div`
  display: flex;
  padding: 19px 44px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const MobileHoverGitBtn = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const HoverPapersBtn = styled.div`
  display: flex;
  padding: 19px 44px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const MobileHoverPapersBtn = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const HoverXBtn = styled(XSvg)`
  width: 64px;
  height: auto;
`;

const MobileHoverXBtn = styled(XSvg)`
  width: 44px;
  height: auto;
`;

const BlackLogo = styled.div`
  width: 60%;
  height: auto;
`;

const MobileBlackLogo = styled.div`
  width: 240px;
  height: auto;
`;

interface SectionProps {
  id?: string;
  className?: string;
}

export default function AlithBottom() {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const lineVariants = {
    hidden: {
      width: '0%',
    },
    visible: {
      width: '56px',
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const logoVariants = {
    hidden: {
      opacity: 0,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1.5,
        ease: 'easeIn',
      },
    }),
  };

  const handleButtonClick = () => {
    window.open('https://t.me/lazai_global', '_blank');
  };

  return isMobile ? (
    <MobileWrapper ref={ref}>
      <TitleBox>
        {inView ? (
          <TextAnimation text={`Ready to Build\nwith Alith?`} fontSize="36px" />
        ) : (
          <Title></Title>
        )}
        <Line
          variants={lineVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        />
      </TitleBox>
      <HoverButtonBox
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={1}
      >
        <IconButton
          href={AlithGitLink}
          target="_blank"
          color="inherit"
          sx={{ padding: 0 }}
        >
          <MobileHoverGitBtn>GitHub</MobileHoverGitBtn>
        </IconButton>
        <IconButton
          href={AlithGitbookLink}
          target="_blank"
          color="inherit"
          sx={{ padding: 0 }}
        >
          <MobileHoverPapersBtn>Docs</MobileHoverPapersBtn>
        </IconButton>
        <IconButton
          href={AlithXLink}
          target="_blank"
          color="inherit"
          sx={{ padding: 0 }}
        >
          <MobileHoverXBtn />
        </IconButton>
      </HoverButtonBox>
    </MobileWrapper>
  ) : (
    <Wrapper ref={ref}>
      <TitleBox>
        {inView ? (
          <TextAnimation text="Ready to Build with Alith?" fontSize="48px" />
        ) : (
          <Title></Title>
        )}
        <Line
          variants={lineVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        />
      </TitleBox>
      <HoverButtonBox
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={1}
      >
        <IconButton
          href={AlithGitLink}
          target="_blank"
          color="inherit"
          sx={{ padding: 0 }}
        >
          <HoverGitBtn>GitHub</HoverGitBtn>
        </IconButton>
        <IconButton
          href={AlithGitbookLink}
          target="_blank"
          color="inherit"
          sx={{ padding: 0 }}
        >
          <HoverPapersBtn>Docs</HoverPapersBtn>
        </IconButton>
        <IconButton
          href={AlithXLink}
          target="_blank"
          color="inherit"
          sx={{ padding: 0 }}
        >
          <HoverXBtn />
        </IconButton>
      </HoverButtonBox>
    </Wrapper>
  );
}
