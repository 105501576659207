import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Skeleton } from '@mui/material';
import { useBlogs } from 'hooks/useBlogs';

const MarkdownContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
  font-family: Inter;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #ffffff;
    font-family: Inter;
    font-weight: 600;
    margin: 24px 0 16px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 16px;
    color: #ffffff;
    font-family: Inter;
  }

  img {
    max-width: 100%;
    border-radius: 12px;
    margin: 24px 0;
  }

  code {
    background: rgba(128, 138, 189, 0.1);
    padding: 2px 6px;
    border-radius: 4px;
    font-family: monospace;
    color: #ffffff;
  }

  pre {
    background: rgba(128, 138, 189, 0.1);
    padding: 16px;
    border-radius: 8px;
    overflow-x: auto;
    margin: 16px 0;

    code {
      background: none;
      padding: 0;
      color: #ffffff;
    }
  }

  a {
    color: #3b3f8c;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    margin: 16px 0;
    padding-left: 24px;
    color: #ffffff;
    font-family: Inter;
  }

  li {
    margin: 8px 0;
    color: #ffffff;
  }

  blockquote {
    border-left: 4px solid #3b3f8c;
    margin: 16px 0;
    padding-left: 16px;
    color: #ffffff;
  }
`;

const LoadingSkeleton = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
`;

interface MarkdownContentProps {
  id: string;
}

export default function MarkdownContent({ id }: MarkdownContentProps) {
  const [content, setContent] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const { fetchBlogContent } = useBlogs();

  useEffect(() => {
    const loadContent = async () => {
      try {
        setLoading(true);
        const markdown = await fetchBlogContent(id);
        setContent(markdown);
      } catch (error) {
        console.error('Failed to load blog content:', error);
      } finally {
        setLoading(false);
      }
    };

    loadContent();
  }, [id, fetchBlogContent]);

  if (loading) {
    return (
      <LoadingSkeleton>
        <Skeleton
          variant="text"
          height={40}
          sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
        />
        <Skeleton
          variant="text"
          height={20}
          sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
        />
        <Skeleton
          variant="text"
          height={20}
          sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
        />
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{
            bgcolor: 'rgba(128, 138, 189, 0.1)',
            borderRadius: '8px',
            margin: '24px 0',
          }}
        />
        <Skeleton
          variant="text"
          height={20}
          sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
        />
        <Skeleton
          variant="text"
          height={20}
          sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
        />
      </LoadingSkeleton>
    );
  }

  return (
    <MarkdownContainer>
      <ReactMarkdown>{content}</ReactMarkdown>
    </MarkdownContainer>
  );
}
