import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useBlogs, BlogItem } from 'hooks/useBlogs';
import { Typography, IconButton, Skeleton } from '@mui/material';
import { Ido, Line } from '../components/UI';
import ShareButtons from '../components/ShareButtons';
import MarkdownContent from '../components/MarkdownContent';
import { ArrowBack } from '@mui/icons-material';
import moment from 'moment';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 1200px;
  padding: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 20px;
  `};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
`;

const TagTime = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

const Tag = styled.div`
  padding: 4px 16px;
  border-radius: 20px;
  background: #3b3f8c;
`;

const TagText = styled(Typography)`
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
`;

const Time = styled(Typography)`
  color: #808abd;
  font-family: Inter;
  font-size: 14px;
`;

const Title = styled(Typography)`
  color: #ffffff;
  font-family: Syne;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  max-width: 800px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 32px;
  `};
`;

const Description = styled(Typography)`
  color: #808abd;
  font-family: Inter;
  font-size: 20px;
  line-height: 1.5;
  max-width: 800px;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`;

const Banner = styled.div<{ url: string }>`
  width: 100%;
  height: 600px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 240px;
  `};
`;

const ArticleContent = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  color: #ffffff;
  font-family: Syne;
  font-size: 16px;
  line-height: 1.8;

  h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 40px 0 20px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 24px;
    `};
  }

  p {
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    border-radius: 12px;
    margin: 20px 0;
  }
`;

const BackButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    left: 0;
    top: 0;
    color: #808abd;

    &:hover {
      color: #ffffff;
      background: rgba(128, 138, 189, 0.1);
    }
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default function BlogDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { findBlogByLink } = useBlogs();
  const [blog, setBlog] = useState<BlogItem | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBlogData = async () => {
      if (!id) return;

      setLoading(true);
      try {
        const blogData = await findBlogByLink(id);
        if (blogData) {
          setBlog(blogData);
        } else {
          // 如果找不到博客，跳转回列表页
          navigate('/blogs');
        }
      } catch (error) {
        console.error('Failed to load blog data:', error);
        navigate('/blogs');
      } finally {
        setLoading(false);
      }
    };

    loadBlogData();
  }, [id]);

  const handleBack = () => {
    navigate('/blogs');
  };

  const formattedTime = blog ? moment(blog.time).format('MMM Do YY') : '';

  if (!blog && !loading) return null;

  return (
    <Wrapper>
      <Content>
        <Line />
        <HeaderContainer>
          <BackButton onClick={handleBack}>
            <ArrowBack />
          </BackButton>
          {loading ? (
            <Header>
              <TagTime>
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={32}
                  sx={{
                    bgcolor: 'rgba(128, 138, 189, 0.1)',
                    borderRadius: '20px',
                  }}
                />
                <Skeleton
                  variant="text"
                  width={120}
                  sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
                />
              </TagTime>
              <Skeleton
                variant="text"
                width="80%"
                height={60}
                sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
              />
              <Skeleton
                variant="text"
                width="60%"
                height={24}
                sx={{ bgcolor: 'rgba(128, 138, 189, 0.1)' }}
              />
            </Header>
          ) : (
            <Header>
              <TagTime>
                <Tag>
                  <TagText>{blog?.tag}</TagText>
                </Tag>
                <Ido />
                <Time>{formattedTime}</Time>
              </TagTime>
              <Title>{blog?.title}</Title>
              <Description>{blog?.desc}</Description>
            </Header>
          )}
        </HeaderContainer>
        {loading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={600}
            sx={{
              bgcolor: 'rgba(128, 138, 189, 0.1)',
              borderRadius: '16px',
              marginBottom: '40px',
            }}
          />
        ) : (
          <Banner url={blog?.banner || ''} />
        )}
        {!loading && <ShareButtons url={window.location.href} />}
        {id && <MarkdownContent id={id} />}
      </Content>
    </Wrapper>
  );
}
