import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import LandingPage from 'pages/landingPage';
import LoyaltyPoint from 'pages/LoyaltyPoint';
import FAQ from 'pages/Faq';
import Terms from 'pages/Terms';
import Alith from 'pages/Alith';
import BlogList from 'pages/Blogs/BlogList';
import BlogDetail from 'pages/Blogs/BlogDetail';
import BlogLayout from 'pages/Blogs/Layout';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow-x: hidden;
  box-sizing: border-box;
  background: #020735;

  &.white {
    background-color: #fff;
  }

  position: relative;
  z-index: 1;

  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function App() {
  return (
    <Wrapper>
      <Router>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/landingPage" element={<Navigate to="/" />} />
          <Route path="/loyaltyPoint" element={<LoyaltyPoint />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/alith" element={<Alith />} />
          <Route path="/blogs" element={<BlogLayout />}>
            <Route index element={<BlogList />} />
            <Route path=":id" element={<BlogDetail />} />
          </Route>
        </Routes>
      </Router>
    </Wrapper>
  );
}

export default observer(App);
