import { HeaderLogoSvg } from 'assets/img';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HoverButton from 'components/HoverButton';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import { TechLink, DocsLink, AlithGitbookLink } from 'constant';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div<{ scrolled: boolean }>`
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px;
  position: fixed;
  top: 0;
  z-index: 999999;
  width: 100%;
  transition: background-color 0.3s ease;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6px 20px;
    position: fixed;
  `}
`;

const MobileWrapper = styled.div`
  display: flex;
  padding: 19px 24px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999999;
  width: 100%;
`;

const ButtonBox = styled.div<{
  scrolled: boolean;
  opacity: number;
  page: string;
}>`
  display: flex;
  gap: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ page }) =>
    page === 'landingPage' ? '5px 28px 5px 28px' : '5px 28px'};
  border-radius: 22px;
  margin-left: ${({ page }) => (page === 'landingPage' ? '140px' : '0')};
  min-height: 44px;

  background: rgba(255, 255, 255, ${({ opacity }) => opacity});
  color: ${({ scrolled }) => (scrolled ? '#000' : '#fff')};
`;

const AnimationBox = styled.div<{ scrolled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ scrolled }) => (scrolled ? '150px' : '0')};
  opacity: ${({ scrolled }) => (scrolled ? 1 : 0)};
  transition: all 0.3s ease-in-out;
`;

const RightBox = styled.div<{ scrolled: boolean }>`
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  transform: ${({ scrolled }) =>
    scrolled ? 'translateY(-120px)' : 'translateY(0)'};
  transition: transform 0.3s ease;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 34px;
  cursor: pointer;
`;

const ButtonText = styled.span`
  text-align: center;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
`;

const Logo = styled(HeaderLogoSvg)<{ scrolled: boolean }>`
  width: 128px;
  height: auto;
  transform: ${({ scrolled }) =>
    scrolled ? 'translateY(-120px)' : 'translateY(0)'};
  transition: transform 0.3s ease;
`;

const MobileLogo = styled(HeaderLogoSvg)`
  width: 128px;
  height: auto;
`;

const ToggleButton = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 24px;
  height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 10;

  &::before,
  &::after,
  & > span {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 5px;
    transition: transform 0.5s, opacity 0.5s;
  }

  &::before {
    top: 0;
    transform: translateY(0);
  }

  &::after {
    bottom: 0;
    transform: translateY(0);
  }

  & > span {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }

  &.open::before {
    transform: translateY(7.5px) rotate(45deg);
  }

  &.open::after {
    transform: translateY(-7.5px) rotate(-45deg);
  }

  &.open > span {
    opacity: 0;
  }
`;

interface HeaderProps {
  scrollTop: number;
  open?: boolean;
  page?: string;
  config?: any;
  onNavigate: (sectionId: string) => void;
  onMenuClick?: () => void;
  goPointPage?: () => void;
  goHomePage?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  scrollTop,
  open,
  page = 'landingPage',
  onNavigate,
  onMenuClick,
  goHomePage,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const navigate = useNavigate();

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 1,
        duration: 1.5,
        ease: 'easeIn',
      },
    }),
  };

  useEffect(() => {
    const scrollThreshold = 100;
    const currentScrollTop = scrollTop;

    setScrolled(currentScrollTop > scrollThreshold);
    const calculatedOpacity = Math.min(currentScrollTop / scrollThreshold, 0.9);
    setOpacity(calculatedOpacity);

    return () => {};
  }, [scrollTop]);

  const openLink = (type: string) => {
    if (type === 'Tech Lightpaper') {
      window.open(TechLink, '_blank');
    } else if (type === 'Docs') {
      window.open(DocsLink, '_blank');
    } else if (type === 'Alith: AI Agent Framework') {
      // window.open(AlithGitbookLink, '_blank');
      onNavigate('Alith');
    } else if (type === 'Blogs') {
      navigate('/blogs');
    }
  };

  return isMobile ? (
    <MobileWrapper>
      <div onClick={goHomePage}>
        <MobileLogo />
      </div>
      <ToggleButton className={open ? 'open' : ''} onClick={onMenuClick}>
        <span />
      </ToggleButton>
    </MobileWrapper>
  ) : (
    <Wrapper scrolled={scrolled}>
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={0}
        style={{
          zIndex: 2,
        }}
        onClick={goHomePage}
      >
        <Logo scrolled={scrolled} />
      </motion.div>
      {page === 'landingPage' ? (
        <ButtonBox scrolled={scrolled} opacity={opacity} page={page}>
          <Button
            onClick={() => {
              onNavigate('Network');
            }}
          >
            <ButtonText>LazAI Network</ButtonText>
          </Button>
          <Button
            onClick={() => {
              onNavigate('Mechanism');
            }}
          >
            <ButtonText>Solution</ButtonText>
          </Button>
          <Button
            onClick={() => {
              openLink('Alith: AI Agent Framework');
            }}
          >
            <ButtonText>Alith: AI Agent Framework</ButtonText>
          </Button>
        </ButtonBox>
      ) : page === 'loyaltyPoint' ? (
        <ButtonBox scrolled={scrolled} opacity={opacity} page={page}>
          <Button onClick={goHomePage}>
            <ButtonText>Home</ButtonText>
          </Button>
          <Button
            onClick={() => {
              onNavigate('Participate');
            }}
          >
            <ButtonText>Participate</ButtonText>
          </Button>
          <Button
            onClick={() => {
              onNavigate('Discover');
            }}
          >
            <ButtonText>Discover</ButtonText>
          </Button>
        </ButtonBox>
      ) : page === 'alith' ? (
        <ButtonBox scrolled={scrolled} opacity={opacity} page={page}>
          <Button onClick={goHomePage}>
            <ButtonText>Home</ButtonText>
          </Button>
          <Button
            onClick={() => {
              onNavigate('Framework');
            }}
          >
            <ButtonText>Framework</ButtonText>
          </Button>
          <Button
            onClick={() => {
              onNavigate('Features');
            }}
          >
            <ButtonText>Features</ButtonText>
          </Button>
        </ButtonBox>
      ) : null}
      {page === 'landingPage' || page === 'blogs' ? (
        <RightBox scrolled={scrolled}>
          <ButtonText
            onClick={() => {
              openLink('Tech Lightpaper');
            }}
          >
            Tech Lightpaper
          </ButtonText>
          <ButtonText
            onClick={() => {
              openLink('Blogs');
            }}
          >
            Blogs
          </ButtonText>
          <ButtonText
            onClick={() => {
              openLink('Docs');
            }}
          >
            Docs
          </ButtonText>
          <ButtonText
            onClick={() => {
              onNavigate('FAQ');
            }}
          >
            FAQ
          </ButtonText>
        </RightBox>
      ) : (
        <div style={{ width: '100px' }} />
      )}
    </Wrapper>
  );
};

export default Header;
