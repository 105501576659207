import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `}
`;

const SkeletonCard = styled.div`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  background: #010633;
`;

const SkeletonImage = styled.div`
  width: 100%;
  height: 240px;
  background: linear-gradient(to right, #1a1a3a 8%, #2a2a4a 18%, #1a1a3a 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.2s linear infinite forwards;
`;

const SkeletonContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SkeletonTag = styled.div`
  width: 80px;
  height: 24px;
  border-radius: 12px;
  background: linear-gradient(to right, #1a1a3a 8%, #2a2a4a 18%, #1a1a3a 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.2s linear infinite forwards;
`;

const SkeletonTitle = styled.div`
  width: 90%;
  height: 24px;
  border-radius: 4px;
  background: linear-gradient(to right, #1a1a3a 8%, #2a2a4a 18%, #1a1a3a 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.2s linear infinite forwards;
`;

const SkeletonDesc = styled.div`
  width: 100%;
  height: 16px;
  border-radius: 4px;
  background: linear-gradient(to right, #1a1a3a 8%, #2a2a4a 18%, #1a1a3a 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.2s linear infinite forwards;
  margin-bottom: 8px;
`;

const BlogSkeleton: React.FC = () => {
  return (
    <SkeletonContainer>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <SkeletonCard key={item}>
          <SkeletonImage />
          <SkeletonContent>
            <SkeletonTag />
            <SkeletonTitle />
            <div>
              <SkeletonDesc />
              <SkeletonDesc />
            </div>
          </SkeletonContent>
        </SkeletonCard>
      ))}
    </SkeletonContainer>
  );
};

export default BlogSkeleton;
