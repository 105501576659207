import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { PointMaskSvg } from 'assets/img';
import { Typography, IconButton } from '@mui/material';
import { XSvg } from 'assets/img';
import { AlithGitLink, AlithGitbookLink, AlithXLink } from 'constant';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  aspect-ratio: 100 / 56;
  position: relative;
  z-index: 1;
  padding: 0 88px;
  background: url(${require('assets/img/alith_detial_bg.png')}) no-repeat center
    center;
  background-size: 100% 100%;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background: url(${require('assets/img/alith_detial_bg.png')}) no-repeat center
    center;
  background-size: cover;
`;

const PointMask = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
`;

const MobilePointMask = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
  position: relative;
  z-index: 999;
`;

const MobileContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 999;
  padding: 140px 28px 100px 28px;
  gap: 1.5vh;
`;

const Title = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
`;

const Subtitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
`;

const MobileSubtitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
`;

const AnimationBox = styled(motion.div)`
  width: 100%;
`;

const HoverButtonBox = styled(motion.div)`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const HoverGitBtn = styled.div`
  display: flex;
  padding: 19px 44px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const MobileHoverGitBtn = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const HoverPapersBtn = styled.div`
  display: flex;
  padding: 19px 44px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const MobileHoverPapersBtn = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const HoverXBtn = styled(XSvg)`
  width: 64px;
  height: auto;
`;

const MobileHoverXBtn = styled(XSvg)`
  width: 44px;
  height: auto;
`;

const AlithDetail: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const variants = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.4,
        duration: 0.8,
      },
    }),
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleEnded = () => {
      video.currentTime = 0;
    };

    video.addEventListener('ended', handleEnded);
    return () => {
      video.removeEventListener('ended', handleEnded);
    };
  }, []);

  return isMobile ? (
    <MobileWrapper>
      <video
        ref={videoRef}
        src={'/video/alith_header.mp4'}
        autoPlay={true}
        loop={false}
        muted
        playsInline
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <MobilePointMask
        src={require('assets/img/alith_mobile_header_mask.png')}
      />
      <MobileContentBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
        >
          <MobileTitle>
            Build with Alith
            <br />
            AI Agent
            <br />
            Framework
          </MobileTitle>
        </AnimationBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
        >
          <MobileSubtitle>
            Alith is a decentralized AI agent framework tailored to harness the
            capabilities of the LazAI -a decentralized AI platform dedicated to
            building an open, transparent, high-performance, secure, and
            inclusive AI ecosystem.
          </MobileSubtitle>
        </AnimationBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2}
        ></AnimationBox>

        <HoverButtonBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={3}
        >
          <IconButton
            href={AlithGitLink}
            target="_blank"
            color="inherit"
            sx={{ padding: 0 }}
          >
            <MobileHoverGitBtn>GitHub</MobileHoverGitBtn>
          </IconButton>
          <IconButton
            href={AlithGitbookLink}
            target="_blank"
            color="inherit"
            sx={{ padding: 0 }}
          >
            <MobileHoverPapersBtn>Docs</MobileHoverPapersBtn>
          </IconButton>
          <IconButton
            href={AlithXLink}
            target="_blank"
            color="inherit"
            sx={{ padding: 0 }}
          >
            <MobileHoverXBtn />
          </IconButton>
        </HoverButtonBox>
      </MobileContentBox>
    </MobileWrapper>
  ) : (
    <Wrapper>
      <video
        ref={videoRef}
        src={'/video/alith_header.mp4'}
        autoPlay={true}
        loop={false}
        muted
        playsInline
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <PointMask src={require('assets/img/alith_header_mask.png')} />
      <ContentBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={0}
        >
          <Title>
            Build with Alith
            <br />
            AI Agent
            <br />
            Framework
          </Title>
        </AnimationBox>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={1}
        >
          <Subtitle>
            Alith is a decentralized AI agent framework tailored to harness the
            capabilities of the LazAI -a decentralized AI platform dedicated to
            building an open, transparent, high-performance, secure, and
            inclusive AI ecosystem.
          </Subtitle>
        </AnimationBox>

        <HoverButtonBox
          variants={variants}
          initial="hidden"
          animate="visible"
          custom={2}
        >
          <IconButton
            href={AlithGitLink}
            target="_blank"
            color="inherit"
            sx={{ padding: 0 }}
          >
            <HoverGitBtn>GitHub</HoverGitBtn>
          </IconButton>
          <IconButton
            href={AlithGitbookLink}
            target="_blank"
            color="inherit"
            sx={{ padding: 0 }}
          >
            <HoverPapersBtn>Docs</HoverPapersBtn>
          </IconButton>
          <IconButton
            href={AlithXLink}
            target="_blank"
            color="inherit"
            sx={{ padding: 0 }}
          >
            <HoverXBtn />
          </IconButton>
        </HoverButtonBox>
      </ContentBox>
    </Wrapper>
  );
};

export default AlithDetail;
