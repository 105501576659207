import React from 'react';
import styled from 'styled-components';
import { IconButton, Snackbar } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
`;

const ShareText = styled.div`
  color: #808abd;
  font-family: Syne;
  font-size: 16px;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: #808abd;
    padding: 8px;

    &:hover {
      color: #ffffff;
      background: rgba(128, 138, 189, 0.1);
    }
  }
`;

// X (Twitter) 图标组件
const XIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
);

interface ShareButtonsProps {
  url: string;
}

export default function ShareButtons({ url }: ShareButtonsProps) {
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const handleShare = (platform: string) => {
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url,
      )}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url,
      )}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url,
      )}`,
    };

    window.open(
      shareUrls[platform as keyof typeof shareUrls],
      '_blank',
      'width=600,height=400',
    );
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setOpen(true);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ShareContainer>
        <ShareText>Share on</ShareText>
        <IconsContainer>
          <StyledIconButton onClick={() => handleShare('twitter')}>
            <XIcon />
          </StyledIconButton>
          <StyledIconButton onClick={() => handleShare('facebook')}>
            <FacebookIcon />
          </StyledIconButton>
          <StyledIconButton onClick={() => handleShare('linkedin')}>
            <LinkedInIcon />
          </StyledIconButton>
          <StyledIconButton
            onClick={handleCopy}
            sx={{
              color: copied ? '#4CAF50' : '#808abd',
            }}
          >
            {copied ? <CheckIcon /> : <ContentCopyIcon />}
          </StyledIconButton>
        </IconsContainer>
      </ShareContainer>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Link copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
}
