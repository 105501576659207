import React, { useState } from 'react';
import styled from 'styled-components';
import Banner from '../components/banner';
import BlogTabs from '../components/BlogTabs';
import BlogList from '../components/BlogList';
import BlogSkeleton from '../components/BlogSkeleton';
import { useBlogs } from 'hooks/useBlogs';
import SortButton, { SortOrder } from '../components/SortButton';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  padding-top: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0 10px;
  `};
`;

interface BlogItem {
  title: string;
  desc: string;
  tag: string;
  banner: string;
  time: string;
  link: string;
}

export default function BlogListPage() {
  const [activeTab, setActiveTab] = useState('All');
  const { loading, blogItems, filteredItems, tags, filterByTag, sortItems } =
    useBlogs();
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    filterByTag(tab, sortOrder);
  };

  const handleSortChange = (order: SortOrder) => {
    setSortOrder(order);
    sortItems(filteredItems, order);
  };

  const renderContent = () => {
    if (loading) {
      return <BlogSkeleton />;
    }

    return <BlogList items={filteredItems} activeTag={activeTab} />;
  };

  return (
    <Wrapper>
      <Banner />
      <InnerWrapper>
        {!loading && blogItems.length > 0 && (
          <TabsContainer>
            <BlogTabs
              tabs={tags}
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />
            <SortButton order={sortOrder} onOrderChange={handleSortChange} />
          </TabsContainer>
        )}
        {renderContent()}
      </InnerWrapper>
    </Wrapper>
  );
}
