import React, { useMemo } from 'react';
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components';
import { Text, TextProps } from 'rebass';
import { darken } from 'polished';
import { isMobile } from 'react-device-detect';

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
  upToMoreLarge: 1440,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export function color() {
  return {
    bg01: '#725AF2',
    bg02: '#E7F3FE',
    bg03: '#F3F3F3',
    bg04: '#E7F3FE',
    bg05: '#0064C1',

    t01: '#000',
    t02: '#9395A4',
    t03: '#032B43',
    t04: '#3873FF',
    t05: '#fff',
  };
}

export function theme(): DefaultTheme {
  return {
    mediaWidth: mediaWidthTemplates,
    c: color(),
  };
}

export const ThemedGlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    padding: 0;
  }
  #root {
    height: 100%;
    width: 100%;
    padding: 0;
  }

  * {
    ::-webkit-scrollbar {
        width: 8px;
        height: 100%;
        background-color: transparent;
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #E7F3FE;
        border-radius: 8px;
    }
  }
  body {
    margin: 0;
    height: 100%;
    width: 100%;
  }

  .MuiCalendarPicker-root {
    background: #fff;
    color: #000;

    .MuiSvgIcon-root  {
      fill : #000;
    }

    .MuiCalendarPicker-viewTransitionContainer {
      .MuiTypography-root {
        color: #000;
        background-color: transparent;
      }
    }
    .PrivatePickersSlideTransition-root {
      .MuiPickersDay-root {
        background-color: transparent;
        font-weight: 600;
        color: #000;

        &.Mui-selected {
          background: #0064C1;
          border-radius: 8px;
        }
      }
    }
  }
  .MuiClockPicker-root {
    background: #fff;

    .MuiPickersArrowSwitcher-button {
      &.Mui-disabled {
        svg {
          fill: ${({ theme }) => darken(0.4, theme.c.t02)}
        }
      }
      svg {
        fill: #000;
      }
    }
    .css-eziifo {
      background-color: ${({ theme }) => theme.c.bg02}; 
      span {
        color: #000;
      }
    }
    .MuiButtonBase-root {
      color: #000;
      .MuiTypography-root {
        color: ${({ theme }) => theme.c.t01};
      }
    }
  }
  .MuiInputBase-root {
    input, textarea {
      font-family: 'Space Grotesk', sans-serif !important;
    }
  }

  .MuiInputBase-root,.MuiButtonBase-root {
    font-family: 'Space Grotesk', sans-serif !important;
  }
  * {
    font-family: 'Syne', sans-serif;
    box-sizing: border-box;
  }
`;

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const themeObject = useMemo(() => theme(), []);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

interface TypeProps extends TextProps {
  ellipsis?: boolean;
  wordBreak?: boolean;
  mobileFz?: number;
  lineHeight?: number;
  fontFamily?: string;
}

const TextWrapper = styled(Text)<TypeProps>`
  color: ${({ color, theme }) => (theme.c as any)[color as string]};
  width: ${({ ellipsis, wordBreak }) =>
    ellipsis || wordBreak ? '100%' : 'unset'};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'unset')};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'unset')};
  white-space: ${({ ellipsis }) => (ellipsis ? 'nowrap' : 'unset')};
  word-break: ${({ wordBreak }) => (wordBreak ? 'break-all' : 'unset')};
  font-family: ${({ fontFamily }) => fontFamily || 'Syne, sans-serif'};
  line-height: ${({ lineHeight }) => lineHeight || 'normal'};
`;

export const TYPE = {
  main(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={500}
        color="t01"
        {...props}
        fontSize={fontSize || 16}
        lineHeight={props.lineHeight || 1.5}
        fontFamily={props.fontFamily || 'Syne, sans-serif'}
      />
    );
  },

  btn(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={700}
        color="t05"
        {...props}
        fontSize={fontSize || 16}
        lineHeight={props.lineHeight || 1.2}
        fontFamily={props.fontFamily || 'Syne, sans-serif'}
      />
    );
  },

  title(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={700}
        color="t05"
        {...props}
        fontSize={fontSize || '36px'}
        lineHeight={props.lineHeight || 1.3}
        fontFamily={props.fontFamily || 'Syne, sans-serif'}
      />
    );
  },

  desc(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={400}
        color="t05"
        {...props}
        fontSize={fontSize || '18px'}
        lineHeight={props.lineHeight || 1.5}
        fontFamily={props.fontFamily || 'Inter, sans-serif'}
      />
    );
  },

  mainTitle(props: TypeProps) {
    const fontSize =
      isMobile && props.mobileFz ? props.mobileFz : props.fontSize;
    return (
      <TextWrapper
        fontWeight={700}
        color="t05"
        {...props}
        fontSize={fontSize || '68px'}
        lineHeight={props.lineHeight || '80px'}
        fontFamily={props.fontFamily || 'Syne, sans-serif'}
      />
    );
  },
};
