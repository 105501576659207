import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, Menu, MenuItem } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ReactComponent as SortSVG } from 'assets/img/sort_switch.svg';

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: #808abd;
    padding: 8px;
    border-radius: 20px;
    background: rgba(59, 63, 140, 0.2);

    &:hover {
      background: rgba(59, 63, 140, 0.3);
      color: #ffffff;
    }
  }
`;

const SortIcon = styled(SortSVG)`
  width: 24px;
  height: 24px;
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background: #010633;
    border: 1px solid #3b3f8c;
    border-radius: 12px;
    min-width: 160px;
    padding: 0;
    .MuiList-root {
      padding: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: #808abd;
    font-family: Inter;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    margin: 0;

    &:hover {
      background: rgba(59, 63, 140, 0.2);
    }

    &.active {
      color: #ffffff;
      background: #3b3f8c;
    }

    &:first-child {
      border-radius: 12px 12px 0 0;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }
`;

export type SortOrder = 'desc' | 'asc';

interface SortButtonProps {
  order: SortOrder;
  onOrderChange: (order: SortOrder) => void;
}

export default function SortButton({ order, onOrderChange }: SortButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderChange = (newOrder: SortOrder) => {
    onOrderChange(newOrder);
    handleClose();
  };

  return (
    <>
      <StyledIconButton onClick={handleClick}>
        <SortIcon />
      </StyledIconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem
          onClick={() => handleOrderChange('desc')}
          className={order === 'desc' ? 'active' : ''}
        >
          Time <ArrowDownwardIcon fontSize="small" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => handleOrderChange('asc')}
          className={order === 'asc' ? 'active' : ''}
        >
          Time <ArrowUpwardIcon fontSize="small" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}
