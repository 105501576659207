import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Typography, IconButton } from '@mui/material';
import { XSvg, TelegramSvg, MediumCircleFillSvg } from 'assets/img';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import TextAnimation from 'components/TextAnimation';
import { AlithTGLink, AlithXLink } from 'constant';
import { useInView } from 'react-intersection-observer';

const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 180px 120px 100px 120px;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  flex-direction: row;
  position: relative;
`;

const MobileWrapper = styled.div`
  display: flex;
  /* height: 100vh; */
  width: 100vw;
`;

const MobileContainer = styled.div`
  display: flex;
  overflow: hidden;
  gap: 40px;
  flex-direction: column;
  padding: 100px 28px;
  position: relative;
  z-index: 999;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 999;
  position: relative;
`;

const MobileTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 999;
`;

const Title = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 40px;
`;

const MobileTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 20px;
`;

const Desc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px;
  width: 410px;
`;

const MobileDesc = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
`;

const SocialIcons = styled(motion.div)`
  display: flex;
  gap: 16px;
`;

const X = styled(XSvg)`
  width: 32px;
  height: auto;
`;

const Telegram = styled(TelegramSvg)`
  width: 32px;
  height: auto;
`;

const MediumCircleFill = styled(MediumCircleFillSvg)`
  width: 32px;
  height: auto;
`;

const ContentContainer = styled(motion.div)`
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 8px;
  z-index: 999;
  position: relative;
  gap: 50px;
  display: flex;
  flex-direction: column;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ContentTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: var(--Display-Small-Line-Height, 44px);
`;

const MobileContentTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: var(--Display-Small-Line-Height, 44px);
`;

const ContentLine = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(23, 92, 217, 0.5);
  margin-top: 20px;
`;

const ContentSubTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin-top: 20px;
`;

const MobileContentSubTitle = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  margin-top: 20px;
`;

const ContentText = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
`;

const MobileContentText = styled(Typography)`
  color: #fff;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
`;

const MobileContentContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  z-index: 999;
  gap: 50px;
`;

const MiddleBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
`;
const MobileMiddleBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 999;
  position: relative;
  flex-direction: center;
  align-items: center;
`;

const ImageBox = styled.img`
  width: 100px;
  height: auto;
`;

const MobileImageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  aspect-ratio: 100 / 100;
  position: relative;
  background: #00092f;
  z-index: 999;
`;

const MiddleLine = styled.div`
  width: 2px;
  height: 60vh;
  background: rgba(96, 148, 243, 0.5);
`;

const MobileMiddleLine = styled.div`
  height: 2px;
  width: 100%;
  background: rgba(96, 148, 243, 0.5);
  position: absolute;
  top: 50%;
`;

const Mask = styled.img`
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const MobileMask = styled.img`
  width: 100%;
  height: 60%;
  position: absolute;
  z-index: 1;
`;

const AnimationBox = styled(motion.div)`
  width: 100%;
`;

const AlithContent = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const variants = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.5,
        duration: 1,
      },
    }),
  };

  const fadeInVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  return isMobile ? (
    <MobileWrapper ref={ref}>
      <MobileMask src={require('assets/img/alith_mask.png')} />
      <MobileContainer>
        <MobileTitleBox>
          <AnimationBox
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={0}
          >
            <MobileTitle>
              Key Features & Advantages of Alith Framework
            </MobileTitle>
          </AnimationBox>
          <AnimationBox
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={1}
          >
            <MobileDesc>
              Alith - The first self-developed AI Agent Framework on Metis,
              powered by LazAINetwork
            </MobileDesc>
          </AnimationBox>
          <SocialIcons
            variants={variants}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            custom={2}
          >
            <IconButton href={AlithTGLink} target="_blank" color="inherit">
              <Telegram />
            </IconButton>
            <IconButton href={AlithXLink} target="_blank" color="inherit">
              <X />
            </IconButton>
          </SocialIcons>
        </MobileTitleBox>

        <MobileMiddleBox
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={3}
        >
          <MobileImageBox>
            <ImageBox src={require('assets/img/alith_avatar.png')} />
          </MobileImageBox>
          <MobileMiddleLine />
        </MobileMiddleBox>

        <MobileContentContainer
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={4}
        >
          {contentList.map((item, idx) => (
            <ContentBox key={idx}>
              <MobileContentTitle>{item.title}</MobileContentTitle>
              {item.contentList.map((content, i) => (
                <ContentBox key={i}>
                  <ContentLine />
                  <MobileContentSubTitle>{content.title}</MobileContentSubTitle>
                  <MobileContentText>{content.desc}</MobileContentText>
                </ContentBox>
              ))}
            </ContentBox>
          ))}
        </MobileContentContainer>
      </MobileContainer>
    </MobileWrapper>
  ) : (
    <PageWrapper ref={ref}>
      <Mask src={require('assets/img/alith_mask.png')} />

      <TitleBox>
        {inView && (
          <TextAnimation
            text={`Key Features\n& Advantages\nof Alith\nFramework`}
            fontSize="48px"
            textAlign="left"
            justifyContent="flex-start"
          />
        )}
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={1}
        >
          <Desc>
            Alith - The first self-developed AI Agent Framework on Metis,
            powered by LazAINetwork
          </Desc>
        </AnimationBox>
        <SocialIcons
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={2}
        >
          <IconButton href={AlithTGLink} target="_blank" color="inherit">
            <Telegram />
          </IconButton>
          <IconButton href={AlithXLink} target="_blank" color="inherit">
            <X />
          </IconButton>
        </SocialIcons>
      </TitleBox>

      <MiddleBox
        variants={fadeInVariants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={3}
      >
        <ImageBox src={require('assets/img/alith_avatar.png')} />
        <MiddleLine />
      </MiddleBox>

      <ContentContainer
        ref={containerRef}
        variants={variants}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        custom={4}
      >
        {contentList.map((item, idx) => (
          <ContentBox key={idx}>
            <ContentTitle>{item.title}</ContentTitle>
            {item.contentList.map((content, i) => (
              <ContentBox key={i}>
                <ContentLine />
                <ContentSubTitle>{content.title}</ContentSubTitle>
                <ContentText>{content.desc}</ContentText>
              </ContentBox>
            ))}
          </ContentBox>
        ))}
      </ContentContainer>
    </PageWrapper>
  );
};

const contentList = [
  {
    title: 'Key Features',
    contentList: [
      {
        title: 'High-Performance Inference',
        desc: (
          <>
            ∙ Utilizes Rust’s performance strengths combined with graph
            optimization and model quantization.
            <br />∙ Supports JIT/AOT compilation across CPUs, GPUs, and TPUs for
            dynamic scenario adaptability.
          </>
        ),
      },
      {
        title: 'Developer Accessibility',
        desc: (
          <>
            ∙ Provides cross-language SDKs (Rust, Python, Node.js) and low-code
            orchestration tools.
            <br />∙ Enables one-click deployment and operational functionalities
            to reduce onboarding complexity.
          </>
        ),
      },
      {
        title: 'Web3 Ecosystem Integration',
        desc: (
          <>
            ∙ Designed to seamlessly integrate with decentralized applications
            and blockchain networks.
            <br />∙ Ensures interoperability with existing Web3 and AI
            frameworks.
          </>
        ),
      },
      {
        title: 'Scalability',
        desc: (
          <>
            ∙ Supports complex workflows, from basic internal prompts to
            advanced low-level API customizations.
            <br />∙ Enables customization of roles, goals, tools, operations,
            and behaviors while maintaining abstract clarity.
          </>
        ),
      },
      {
        title: 'Data Sovereignty and Privacy Reasoning',
        desc: (
          <>
            ∙ Leverages LazAI blockchain for data traceability and privacy
            protection.
            <br />∙ Actively removes biased or harmful data while incentivizing
            diverse contributions.
          </>
        ),
      },
    ],
  },

  {
    title: 'Advantages',
    contentList: [
      {
        title: 'Addressing Data Monopolization',
        desc: (
          <>
            By utilizing blockchain-enabled governance, Alith resolves issues
            tied to data centralization. Its consensus-driven mechanisms empower
            contributors to retain ownership and control over their data,
            fostering a culture of equitable participation. The result is a
            vibrant ecosystem where data and resources flow seamlessly without
            traditional gatekeeping barriers.
          </>
        ),
      },
      {
        title: 'Superior Inference Performance',
        desc: (
          <>
            Traditional AI systems face high costs and inefficiencies in
            inference tasks. Alith’s advanced optimizations—including Rust-based
            enhancements and quantization techniques—achieve low-latency,
            high-throughput performance, particularly in resource-constrained
            environments. This ensures robust AI applications across various
            devices and operational contexts.
          </>
        ),
      },
      {
        title: 'Enhanced Developer Usability',
        desc: (
          <>
            Alith democratizes AI development through its SDKs and low-code
            capabilities. Developers, regardless of technical expertise, can
            rapidly create, deploy, and maintain AI agents. This accessibility
            reduces entry barriers, encouraging a broader spectrum of
            contributors to engage with AI technology.
          </>
        ),
      },
      {
        title: 'ScalableDecentralized Governance and Trustbility',
        desc: (
          <>
            With its blockchain backbone, Alith establishes a decentralized
            governance framework that ensures fairness and transparency in asset
            distribution. This approach eliminates reliance on centralized
            authorities, fostering trust and collaboration among stakeholders.
          </>
        ),
      },
      {
        title: 'Ecosystem Affinity',
        desc: (
          <>
            Alith’s compatibility with Web3 ecosystems enhances its versatility.
            It integrates seamlessly with existing decentralized
            infrastructures, enabling users to leverage blockchain’s security
            and transparency benefits. Additionally, it supports
            interoperability with frameworks like Langchain and Eliza, extending
            its utility across diverse applications.
          </>
        ),
      },
    ],
  },
];

export default AlithContent;
