import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextAnimation from 'components/TextAnimation';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { Typography } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: 100 / 55;
  position: relative;
  z-index: 1;
  gap: 48px;
  background: radial-gradient(
    76.59% 97.66% at 31.7% 6.45%,
    #beffff 44.35%,
    #8fbbff 100%
  );
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  gap: 48px;
  background: radial-gradient(
    76.59% 97.66% at 31.7% 6.45%,
    #beffff 44.35%,
    #8fbbff 100%
  );
  padding: 0 28px 100px 28px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 999;
  margin-top: 60px;
`;

const Title = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  margin-left: 14px;
  height: 121.81px;
`;

const Line = styled(motion.div)`
  height: 4px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  position: relative;
  z-index: 999;
`;

const BgImage = styled.img`
  position: absolute;
  top: 70%;
  left: 0;
  transform: translateY(-70%);
  width: 100%;
  height: auto;
  z-index: 1;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 999;
  gap: 36px;
`;

const BotImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const RoadMapCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 23%;
  z-index: 2;
`;

const MobileRoadMapCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  z-index: 2;
`;

const RoadMapTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const RoadMapTitle = styled(Typography)`
  color: #000;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: var(--Display-Small-Line-Height, 44px);
`;

const RoadMapLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #00000033;
`;

const ContentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ListItem = styled.li`
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  strong {
    font-family: Inter;
    font-weight: 700;
  }
`;

const AnimationBox = styled(motion.div)`
  width: 35%;
`;

interface SectionProps {
  id?: string;
  className?: string;
}

export default function RoadMap() {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(2px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 0.5,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const lineVariants = {
    hidden: {
      width: '0%',
    },
    visible: {
      width: '56px',
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const handleButtonClick = () => {
    window.open('https://t.me/lazai_global', '_blank');
  };

  return isMobile ? (
    <MobileWrapper ref={ref}>
      <TitleBox>
        {inView ? (
          <TextAnimation
            text={`Alith's\nRoadMap`}
            fontSize="36px"
            color="#000"
          />
        ) : (
          <Title></Title>
        )}
        <Line
          variants={lineVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        />
      </TitleBox>

      {roadmapData.map((data, index) => (
        <MobileRoadMapCard
          key={index}
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={index + 1}
        >
          <RoadMapTitleBox>
            <RoadMapTitle>{data.title}</RoadMapTitle>
            <RoadMapLine />
          </RoadMapTitleBox>
          <ContentList>
            {data.items.map((item, index) => (
              <ListItem key={index}>
                <strong>{item.title}:</strong> {item.content}
              </ListItem>
            ))}
          </ContentList>
        </MobileRoadMapCard>
      ))}
    </MobileWrapper>
  ) : (
    <Wrapper ref={ref}>
      <TitleBox>
        {inView ? (
          <TextAnimation text="Alith's RoadMap" fontSize="48px" color="#000" />
        ) : (
          <Title></Title>
        )}
        <Line
          variants={lineVariants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        />
      </TitleBox>
      <BgImage src={require('assets/img/alith_bg.png')} />
      <ContentBox>
        <RoadMapCard
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={2}
        >
          <RoadMapTitleBox>
            <RoadMapTitle>{roadmapData[0].title}</RoadMapTitle>
            <RoadMapLine />
          </RoadMapTitleBox>
          <ContentList>
            {roadmapData[0].items.map((item, index) => (
              <ListItem key={index}>
                <strong>{item.title}:</strong> {item.content}
              </ListItem>
            ))}
          </ContentList>
        </RoadMapCard>
        <AnimationBox
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={1}
        >
          <BotImage src={require('assets/img/alith_bot.png')} />
        </AnimationBox>
        <RoadMapCard
          variants={variants}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          custom={3}
        >
          <RoadMapTitleBox>
            <RoadMapTitle>{roadmapData[1].title}</RoadMapTitle>
            <RoadMapLine />
          </RoadMapTitleBox>
          <ContentList>
            {roadmapData[1].items.map((item, index) => (
              <ListItem key={index}>
                <strong>{item.title}:</strong> {item.content}
              </ListItem>
            ))}
          </ContentList>
        </RoadMapCard>
      </ContentBox>
    </Wrapper>
  );
}

const roadmapData = [
  {
    title: 'Q1 2025',
    items: [
      {
        title: 'Models',
        content:
          'Integration with Llama, Perplexity, Grok, Qwen, DeepSeek, and various community-contributed open-source models',
      },
      {
        title: 'Features',
        content:
          'Advanced Prompt Management System, Multi-Agent Collaborative Workflow, Structured LLM Output Extractor, Universal Data Embedding System, High-Performance Inference Engine, Extended Storage Support (Mivus/Postgres/Sqlite), Multi-format Knowledge Integration (Docx/Excel/CSV/Git)',
      },
      {
        title: 'Integrations',
        content:
          'Eliza Node SDK Integration, Comprehensive Langchain Support for Python & Node.js SDKs with advanced chaining capabilities',
      },
      {
        title: 'Tools',
        content:
          'Social Platform Integration (Twitter/Discord/Slack bots), Web3 Toolkit (Smart Contract Interactions for ETH/Solana/Metis)',
      },
      {
        title: 'Operations',
        content:
          'Enhanced Monitoring & Logging System, TEE Security Implementation, Advanced Access Control',
      },
      // {
      //   title: 'Use Cases',
      //   content:
      //     'Advanced ChatBot System with CLI/UI, Decentralized Finance AI Decision Engine',
      // },
      // {
      //   title: 'Community',
      //   content:
      //     'Comprehensive Developer Documentation & Tutorials, Academic & Industry Partnership Program',
      // },
    ],
  },
  {
    title: 'Q2 2025',
    items: [
      {
        title: 'Models',
        content:
          'Custom Model Training Pipeline, Advanced Model Optimization & Compression Toolkit',
      },
      {
        title: 'Features',
        content:
          'Real-time Data Processing Engine, Model Decision Explainability System, Distributed Inference Framework, Adaptive Batch Processing Optimization',
      },
      {
        title: 'Integrations',
        content:
          'LazAI Blockchain Integration with WASM On-chain Inference, IoT Edge Computing Support',
      },
      {
        title: 'Tools',
        content:
          'Polygon Ecosystem Integration, NFT Marketplace Tools (OpenSea/Rarible Support)',
      },
      {
        title: 'Operations',
        content:
          'Serverless Deployment on AWS Lambda, Full-stack Application Support on Vercel',
      },
      {
        title: 'Use Cases',
        content:
          'Advanced Meme Token Creation Platform, AI-Driven Content Generation System',
      },
    ],
  },
];
