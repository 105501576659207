import styled from 'styled-components';

export const Ido = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: rgba(128, 138, 189, 1);
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #808abd;
  margin: 40px 0;
`;
