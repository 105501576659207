export const DATLink =
  'https://lazainetwork.gitbook.io/introduction/lazai-glossary/data-anchoring-token-dat';
export const iDAOLink =
  'https://lazainetwork.gitbook.io/introduction/lazai-glossary/idao';
export const TechLink =
  'https://drive.google.com/file/d/1Tev1iJs5XlCuFPmel9pOFA5DsUqj9aZT/view?usp=sharing';
export const LAVLink =
  'https://drive.google.com/file/d/1_f2HH1RqsxVJ0oc0Y7hpI73aFD26kwNp/view?usp=sharing';
export const DocsLink = 'https://lazainetwork.gitbook.io';

export const AlithGitLink = 'https://github.com/0xLazAI/alith';
export const AlithXLink = 'https://x.com/0xalith';
export const AlithTGLink = 'https://t.me/alithai';
export const AlithGitbookLink = 'https://alith.lazai.network/docs';

export const TelegramLink = 'https://t.me/lazai_global';
export const MediumLink = 'https://lazai.medium.com/';
export const XLink = 'https://x.com/lazainetwork?s=21';

export const TermsRouter = '/terms';
export const FaqRouter = '/faq';
