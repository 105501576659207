import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
  background-image: url(${require('assets/img/blogs/banner.png')});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 300px;
  padding: 0 20px;
  `};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `};
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  position: relative;
  z-index: 999;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  `};
`;

const AnimationBox = styled(motion.div)`
  width: 100%;
`;

const variants = {
  hidden: {
    opacity: 0,
    y: 150,
  },
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.4,
      duration: 0.8,
    },
  }),
};

export default function Banner() {
  return (
    <Wrapper>
      <InnerWrapper>
        <ContentBox>
          <AnimationBox
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={0}
          >
            <TYPE.mainTitle fontSize={48} mobileFz={24} lineHeight={1.2}>
              Knowledge Base:
            </TYPE.mainTitle>
            <TYPE.mainTitle fontSize={60} mobileFz={30}>
              Build, Learn, Align
            </TYPE.mainTitle>
          </AnimationBox>
          <AnimationBox
            variants={variants}
            initial="hidden"
            animate="visible"
            custom={1}
          >
            <TYPE.title fontSize={16} fontWeight={500}>
              Whether you’re a developer or a researcher exploring LazAI, you’ll
              find the resources and guides you need to build AI you can trust.
            </TYPE.title>
          </AnimationBox>
        </ContentBox>
      </InnerWrapper>
    </Wrapper>
  );
}
