import React from 'react';
import styled from 'styled-components';
import HoverButton from 'components/HoverButton';
import TextAnimation from 'components/TextAnimation';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import { Button, Typography, IconButton } from '@mui/material';
import { AlithNormalSvg, AlithHoverSvg, XSvg } from 'assets/img';
import { AlithGitLink, AlithGitbookLink, AlithXLink } from 'constant';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  aspect-ratio: 100 / 52;
  position: relative;
  z-index: 1;
  margin-bottom: 160px;
`;

const MobileWrapper = styled.div`
  display: flex;
  height: 100vh;
  padding: 100px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  z-index: 1;
  position: relative;
`;

const BgBox = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 100 / 56;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const MobileBgBox = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const GradientButton = styled(Button)`
  position: relative;
  border: 2px solid white;
  padding: 20px 28px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 20px;
  font-family: 'Syne', sans-serif;
  text-transform: none;
  width: 310px;
  height: 62px;
  border-radius: 31px;
  overflow: hidden;

  &:hover {
    border-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(90deg, #ff229f, #ff7300);
    transition: height 0.3s ease;
    z-index: -1;
  }

  &:hover::after {
    height: 100%;
  }
`;

const NormalContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 4px;
  padding-right: 24px;
`;

const NormalImage = styled(AlithNormalSvg)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const NormalText = styled(Typography)`
  font-family: Syne;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: white;
`;

const HoverContent = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  padding-right: 24px;
  opacity: 0;
  position: absolute;
  transform: translateY(20px);
  transition: all 0.3s ease-in-out;
`;

const HoverImage = styled(AlithHoverSvg)`
  width: 230px;
  height: 230px;
`;

const HoverContentBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HoverTitle = styled(Typography)`
  color: #fff;
  font-family: Syne;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
`;

const HoverDesc = styled(Typography)`
  color: rgba(255, 255, 255, 0.8);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 6px;
`;

const HoverButtonBox = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const HoverGitBtn = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  background: var(
    --red-gradient,
    linear-gradient(90deg, #ff229f 0%, #ff4570 53.5%, #ff7300 100%)
  );
  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const HoverPapersBtn = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 39px;
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Syne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

const HoverXBtn = styled(XSvg)`
  width: 34px;
  height: auto;
`;

const AnimationBox = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: #2c3e69;
  box-shadow: 0px 9px 9px 0px rgba(0, 0, 0, 0.5);
  width: 610px;
  height: 48px;
  border-radius: 24px;
  position: absolute;
  top: 80px;
  z-index: 9999999;
  transition: all 0.3s ease-in-out;

  &:hover {
    width: 1000px;
    height: 230px;
    border-radius: 20px;

    ${NormalContent} {
      opacity: 0;
      transform: translateY(-20px);
    }

    ${HoverContent} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

interface SectionProps {
  id?: string;
  className?: string;
}

const TopModules: React.FC<SectionProps> = ({ id, className }) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      filter: 'blur(10px)',
    },
    visible: (i: number) => ({
      opacity: 1,
      scale: 1,
      filter: 'none',
      transition: {
        delay: i * 1,
        duration: 1,
        ease: 'easeIn',
      },
    }),
  };

  const handleButtonClick = () => {
    window.open('https://t.me/lazai_global', '_blank');
  };

  return isMobile ? (
    <MobileWrapper ref={ref} id={id} className={className}>
      <MobileBgBox>
        <video
          src={'/video/header.mp4'}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
          }}
        />
      </MobileBgBox>
      <TextAnimation
        text={`An open\nnetwork for the\ncomposable\nand open\nAI environment`}
        fontSize="32px"
      />
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={2.8}
        style={{
          zIndex: 2,
        }}
      >
        <HoverButton
          text="Join LazAI Community"
          fontSize="20px"
          width="80vw"
          height="62px"
          borderRadius="31px"
          onClick={handleButtonClick}
        />
      </motion.div>
    </MobileWrapper>
  ) : (
    <Wrapper id={id} className={className}>
      <BgBox>
        <video
          src={'/video/header.mp4'}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </BgBox>
      <TextAnimation
        text={`An open network for the\ncomposable and open\nAI environment`}
      />
      <motion.div
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={1.5}
        style={{
          zIndex: 2,
        }}
      >
        <GradientButton variant="outlined" onClick={handleButtonClick}>
          Join LazAI Community
        </GradientButton>
      </motion.div>
      <AnimationBox
        variants={variants}
        initial="hidden"
        animate="visible"
        custom={2}
      >
        <NormalContent>
          <NormalImage />
          <NormalText>
            Hey developers! I’m LazAI’s decentralized AI Agent Framework Alith!
          </NormalText>
        </NormalContent>
        <HoverContent>
          <HoverImage />
          <HoverContentBox>
            <HoverTitle>Build AI Agents with Alith Framework</HoverTitle>
            <HoverDesc>
              Hey developers! I’m Alith, a decentralized AI agent framework
              tailored to harness the capabilities of the LazAI.  Alith
              revolutionizes AI with cutting-edge optimization, Web3-powered
              transparency, and seamless cross-language integration—empowering
              developers with decentralized, scalable, and efficient
              intelligence.
            </HoverDesc>
            <HoverButtonBox>
              <IconButton
                href={AlithGitLink}
                target="_blank"
                color="inherit"
                sx={{ padding: 0 }}
              >
                <HoverGitBtn>GitHub</HoverGitBtn>
              </IconButton>
              <IconButton
                href={AlithGitbookLink}
                target="_blank"
                color="inherit"
                sx={{ padding: 0 }}
              >
                <HoverPapersBtn>Docs</HoverPapersBtn>
              </IconButton>
              <IconButton
                href={AlithXLink}
                target="_blank"
                color="inherit"
                sx={{ padding: 0 }}
              >
                <HoverXBtn />
              </IconButton>
            </HoverButtonBox>
          </HoverContentBox>
        </HoverContent>
      </AnimationBox>
    </Wrapper>
  );
};

export default TopModules;
