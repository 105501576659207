import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import Row from 'components/Row';
import { useNavigate } from 'react-router-dom';
import { Ido } from './UI';
import moment from 'moment';

const ItemContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  background: #010633;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

const BannerImage = styled.div<{ url: string }>`
  width: 100%;
  height: 240px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TagTime = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Tag = styled.div`
  padding: 4px 12px;
  border-radius: 16px;
  background: #3b3f8c;
`;

const TagText = styled(Typography)`
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
`;

const Time = styled(Typography)`
  color: #808abd;
  font-family: Inter;
  font-size: 14px;
`;

const Title = styled(Typography)`
  color: #ffffff;
  font-family: Syne;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  height: calc(1.2em * 4);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Description = styled(Typography)`
  color: #808abd;
  font-family: Inter;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  height: calc(1.5em * 4);
`;

const ReadMore = styled(Typography)`
  color: rgba(68, 174, 255, 1);
  font-family: Syne;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface BlogItemProps {
  data: {
    title: string;
    desc: string;
    tag: string;
    banner: string;
    time: string;
    link: string;
  };
}

const BlogItem: React.FC<BlogItemProps> = ({ data }) => {
  const navigate = useNavigate();
  const formattedTime = moment(data.time).format('MMM Do YY');

  const handleClick = () => {
    navigate(`/blogs/${data.link}`);
  };

  return (
    <ItemContainer onClick={handleClick}>
      <BannerImage url={data.banner} />
      <Content>
        <TagTime>
          <Tag>
            <TagText>{data.tag}</TagText>
          </Tag>
          <Ido />
          <Time>{formattedTime}</Time>
        </TagTime>
        <Title onClick={() => navigate(`/blogs/${data.link}`)}>
          {data.title}
        </Title>
        <Description>{data.desc}</Description>
      </Content>
    </ItemContainer>
  );
};

export default BlogItem;
